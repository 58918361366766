<template>
  <Loader :value="promise" theme="default" class="template-residential-template-page">
    <div v-if="data" :class="'template-residential-template-page' + id">
      <div class="container template-residential-template-page__header">
        <h1 class="mt-2">{{ `Type ${data.name}` }}</h1>
        <Slider
          class="template-residential-template-page__header-cover"
          v-if="coverImages.length"
          :value="coverImages"
          :display-thumbnail="true"
        />
      </div>
      <div class=" container template-residential-template-page__content">
        <div class="template-residential-template-page__summary">
          <div v-if="graphicElement" class="template-residential-template-page__summary-header">
            <h1>{{ data.name }}</h1>
            <div
              class="template-residential-template-page__summary-image"
              :style="{
                backgroundImage: `url(${graphicElement.url})`,
              }"
            ></div>
          </div>
          <Summary :value="data" />
        </div>
        <Info :value="data" class="template-residential-template-page__content-info" />
      </div>

      <div class="container">
        <Masonry :value="gallery" class="template-residential-template-page__masonry" />
      </div>
      <section class="section section__roles">
        <ScrollAnchor value="lead" offset="-8rem" :delay="750" />
        <div class="container">
          <Roles />
        </div>
      </section>
      <section class="section section__lead">
        <div class="container">
          <ScrollAnchor value="lead" offset="-8rem" :delay="750" />
          <Lead />
        </div>
      </section>
    </div>
  </Loader>
</template>

<script>
import API from '@/api'
import Summary from '@/components/ResidentialTemplate/Summary'
import Info from '@/components/ResidentialTemplate/Info'
import Masonry from '@kvass/template-gallery/Types/Masonry'
import Gallery from '@kvass/template-gallery/Types/Gallery'
import Slider from '@kvass/media-render/Slider'
import Roles from '@/components/Roles'
import ScrollAnchor from '@kvass/scroll-anchor'
import Lead from '@/components/Project/Lead'

export default {
  props: {
    id: null,
  },
  data() {
    return {
      promise: null,
      data: null,
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        this.fetch(val)
      },
    },
  },
  computed: {
    graphicElement() {
      return (this.$path('data.media.cover') || [])[0]
    },
    coverImages() {
      return (this.$path('data.media.cover') || []).slice(1)
    },
    gallery() {
      return this.$path('data.media.gallery') || []
    },
    floorplan() {
      return (this.$path('data.media.floorplan') || []).map(e => ({
        caption: e.name,
        url: e.url,
        type: e.type,
      }))
    },
  },
  methods: {
    goToProject() {
      return this.$router.push({ name: 'Project' })
    },

    fetch() {
      return (this.promise = API.getResidentialTemplate({
        id: this.id,
      }).then(data => {
        this.data = data.ResidentialTemplate

        return data
      }))
    },
  },
  metaInfo() {
    return {
      title: this.$path('data.name') || this.$t('loading', { resource: '' }),
    }
  },
  components: {
    Summary,
    Masonry,
    Info,
    Gallery,
    Slider,
    Roles,
    ScrollAnchor,
    Lead,
  },
}
</script>

<style lang="scss">
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.template-residential-template-page {
  .section {
    &__lead {
      background-color: var(--primary);
    }
  }

  &__header {
    pointer-events: none;
    $height: 60vh;
    h1 {
      margin-top: 2rem;
      color: var(--primary);
    }
    // display: flex;

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    &-cover {
      background-color: grey;
      @include aspect-ratio(16, 9);
    }
  }
  &__summary {
    $width: 450px;
    max-width: $width;
    position: relative;
    transform: translate(0, -9rem);
    @include respond-below('phone') {
      transform: translate(0, 1rem);
    }

    &-image {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 90px;
    }

    &-header {
      padding: 2rem;
      display: flex;
      align-items: center;
      gap: 2rem;
      background: var(--primary);

      h1 {
        color: white;
        margin-bottom: 0;
        font-size: 4.5rem;

        @include respond-below('phone') {
          font-size: 3rem;
        }
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 0.8fr 2fr;
    gap: 3rem;
    @include respond-below('phone') {
      grid-template-columns: 1fr;
      gap: 0rem;
    }

    &-info {
      padding-top: 5rem;
      @include respond-below('phone') {
        padding: 3rem 0 2rem 0;
      }
    }
  }
  &__masonry {
    .masonry__content {
      gap: 2rem;
    }
  }
}
</style>
