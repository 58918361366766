<template>
  <div class="template-residential-template-summary">
    <div class="template-residential-template-summary__properties">
      <div
        class="template-residential-template-summary__property"
        v-for="(prop, index) in properties"
        :key="index"
      >
        <div class="template-residential-template-summary__property-label">
          {{ prop.label || $tc(prop.key) }}
        </div>
        <div class="template-residential-template-summary__property-value">
          {{ prop.formatted }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  computed: {
    title() {
      return this.$path('value.name')
    },
    properties() {
      if (!this.value || !this.value.properties) return []
      return this.value.properties
        .filter(p => p.formatted)
        .map(i => {
          return {
            ...i,
            formatted: i.formatted.replace('m²', 'KVM'),
          }
        })
    },
  },
}
</script>

<style lang="scss">
.template-residential-template-summary {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: var(--background-secondary);

  justify-content: center;
  @include respond-below('phone') {
    padding: 2rem 2rem;
  }

  &__properties {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  &__property {
    padding: 0.5rem 0;

    display: flex;
    flex-direction: column;

    &-value {
      text-transform: uppercase;
      font-weight: bold;
    }
    &-label {
      opacity: 0.75;
    }
  }
}
</style>
